<template>
  <div class="select-container">
    <select
      @change="onChange"
      @blur="handleBlur"
      v-model="selectedValue"
      id="group-size"
      name="group-size"
      class="u-appearance-none"
      :class="{ 'is-error': blurred === true && selectedValue === defaultOption }"
    >
      <option
        :value="defaultOption"
        :disabled="defaultDisabled"
        selected
      >{{ defaultOption }}</option>
      <option v-for="(number) in maxGroupSize" :value="number" :disabled="shouldDisable(number)">{{ number }}</option>
    </select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GroupSize',
  props: {
    blurred: Boolean,
    defaultOption: String,
    defaultDisabled: {
      type: Boolean,
      default: true
    },
    selectedGroupSize: null,
    availableGroupSize: {
      type: Number
    }
  },
  data() {
    return {
      selectedValue: ''
    }
  },
  computed: {
    ...mapState(['maxGroupSize']),
  },
  mounted() {
    if (this.selectedGroupSize) {
      this.selectedValue = this.selectedGroupSize;
    } else {
      this.selectedValue = this.defaultOption;
    }
  },
  methods: {
    handleBlur() {
      this.$emit('handleblur', 'groupSize');
    },
    onChange(event) {
      const value = this.selectedValue === this.defaultOption ? null : this.selectedValue;
      this.$emit('onSizeChange', value);
    },
    shouldDisable(groupSize) {
      return groupSize > (
        this.availableGroupSize === undefined
        ? this.maxGroupSize
        : this.availableGroupSize
      );
    }
  }
}
</script>
