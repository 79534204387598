<template>
  <div class="payment">
    <div class="text-left mb-2"><span class="tour-tool__description u-color-black">*Required</span></div>
    <div class="row">
      <div class="col-md-6">
        <label for="first-name" class="sr-only">First Name</label>
        <input
          @input="handleChange"
          @blur="handleBlur"
          v-model="firstName"
          name="firstName"
          class="input-border"
          :class="{ 'is-error': blurred.firstName == true && firstName == '' }"
          type="text"
          placeholder="First Name*"
          id="first-name">
      </div>
      <div class="col-md-6">
        <label for="last-name" class="sr-only">Last Name</label>
        <input
          @input="handleChange"
          @blur="handleBlur"
          v-model="lastName"
          name="lastName"
          class="input-border"
          :class="{ 'is-error': blurred.lastName == true && lastName == '' }"
          type="text"
          placeholder="Last Name*"
          id="last-name">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p class="tour-tool__description u-color-black mb-2 text-left">Date of birth*</p>
        <div class="row">
          <div class="col-4 payment__slash">
            <label for="birthday-month" class="sr-only">Birthday Month</label>
            <input
              @input="handleNumberChange"
              @blur="handleBlur"
              v-model="birthdayMonth"
              name="birthdayMonth"
              type="text"
              class="input-border"
              :class="{ 'is-error': blurred.birthdayMonth == true && errors.birthdayMonth }"
              title="Month (Format: MM)"
              placeholder="MM"
              pattern="(\d{2}([\-]\d{1})?)*"
              maxlength="2"
              size="2"
              id="birthday-month">
          </div>
          <div class="col-4 payment__slash">
            <label for="birthday-day" class="sr-only">Birthday Day</label>
            <input
              @input="handleNumberChange"
              @blur="handleBlur"
              v-model="birthdayDay"
              name="birthdayDay"
              type="text"
              class="input-border"
              :class="{ 'is-error': blurred.birthdayDay == true && errors.birthdayDay }"
              title="Day (Format: DD)"
              placeholder="DD"
              pattern="(\d{2}([\-]\d{1})?)*"
              maxlength="2"
              size="2"
              id="birthday-day">
          </div>
          <div class="col-4">
            <label for="birthday-year" class="sr-only">Birthday Year</label>
            <input
              @input="handleNumberChange"
              @blur="handleBlur"
              v-model="birthdayYear"
              name="birthdayYear"
              type="text"
              class="input-border"
              :class="{ 'is-error': blurred.birthdayYear == true && errors.birthdayYear }"
              title="Year (Format: YYYY)"
              placeholder="YYYY"
              pattern="(\d{4}([\-]\d{3})?)*"
              maxlength="4"
              size="4"
              id="birthday-year">
          </div>
        </div>
      </div>
      <div class="col-md-6 d-md-flex align-items-md-end">
        <label for="zip" class="sr-only">Zip code</label>
        <input
          @input="handleNumberChange"
          @blur="handleBlur"
          v-model="zipCode"
          name="zipCode"
          class="input-border"
          :class="{ 'is-error': blurred.zipCode == true && zipCode.length < 5 }"
          type="text"
          title="Zip Code (Format: 12345)"
          placeholder="ZIP Code*"
          pattern="(\d{5}([\-]\d{4})?)*"
          maxlength="5"
          size="5"
          id="zip">
      </div>
    </div>
    <div class="row">
        <div class="col-md-6">
          <label for="email-address" class="sr-only">Email address</label>
          <input
            @input="handleChange"
            @blur="handleBlur"
            v-model="email"
            name="email"
            class="input-border"
            :class="{ 'is-error': blurred.email == true && email == '' }"
            type="email"
            placeholder="Email Address*"
            id="email-address">
        </div>
        <div class="col-md-6">
          <label for="telephone" class="sr-only">Phone Number</label>
          <input
            @input="handleNumberChange"
            @blur="handleBlur"
            v-model="telephone"
            name="telephone"
            class="input-border"
            :class="{ 'is-error': blurred.telephone == true && telephone == '' }"
            type="text"
            placeholder="Phone*"
            id="telephone">
        </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex text-left">
        <div class="payment__checkbox-container d-inline-block">
          <!-- Handle checkbox change -->
          <input
            name="receivePromoEmail"
            v-model="receivePromoEmail"
            type="checkbox"
            id="promotional"
            class="payment__input u-absolute-full">
          <div class="payment__checkmark u-absolute-full"></div>
        </div>
        <label for="promotional" class="payment__promotional tour-tool__description u-color-black ml-3">I would like to receive promotional emails related to Shiner.</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex text-left pt-2">
        <div class="payment__checkbox-container d-inline-block">
          <!-- Handle checkbox change -->
          <input
            name="arriveEarlyPromise"
            v-model="arriveEarlyPromise"
            type="checkbox"
            id="arriveEarlyPromise"
            class="payment__input u-absolute-full">
          <div class="payment__checkmark u-absolute-full"></div>
        </div>
        <label for="arriveEarlyPromise" class="payment__promise tour-tool__description u-color-black ml-3">I understand that I must check in for my tour 15 minutes prior to the start of the tour, or my tour reservation may be cancelled. *</label>
      </div>
    </div>

    <!-- <div class="payment__card mt-5">
      <div class="row">
        <div class="col-12">
          <label for="card-number" class="sr-only">Card Number</label>
          <input
            @input="handleNumberChange"
            @blur="handleBlur"
            v-model="cardNumber"
            name="cardNumber"
            class="input-border"
            :class="{ 'is-error': blurred.cardNumber == true && cardNumber.length < 16 }"
            type="text"
            title="Card Number (Format 16 digits: XXXX XXXX XXXX XXXX)"
            placeholder="Card Number*"
            pattern="(\d{16}([\-]\d{15})?)*"
            maxlength="16"
            size="16"
            id="card-number">
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 text-left">
          <p class="payment__expiration tour-tool__description u-color-black mb-2">Expiration date*</p>

          <div class="row">
            <div class="col-6 payment__slash">
              <label for="expiration-month" class="sr-only">Expiration Date - Month</label>
              <div class="select-container">
                <select
                  @change="handleChange"
                  @blur="handleBlur"
                  v-model="expirationMonth"
                  name="expirationMonth"
                  class="u-appearance-none"
                  :class="{ 'is-error': blurred.expirationMonth == true && expirationMonth == 'MM' }"
                  id="expiration-month">
                  <option value="MM" disabled selected>MM</option>
                  <option v-for="(month) in selectMonths" :value="month">{{ month }}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <label for="expiration-year" class="sr-only">Expiration Date - Year</label>
              <div class="select-container">
                <select
                  @change="handleChange"
                  @blur="handleBlur"
                  v-model="expirationYear"
                  name="expirationYear"
                  id="expiration-year"
                  class="u-appearance-none"
                  :class="{ 'is-error': blurred.expirationYear == true && expirationYear == 'YY' }">
                  <option value="YY" disabled selected>YY</option>
                  <option v-for="(year) in getSelectYears" :value="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-6 text-left">
          <label for="card-verification" class="tour-tool__description u-color-black mb-2">Card Verification # *</label>
          <input
            @input="handleNumberChange"
            @blur="handleBlur"
            v-model="cvv"
            name="cvv"
            class="input-border"
            :class="{ 'is-error': blurred.cvv == true && cvv.length < 3 }"
            type="text"
            title="Card Verification (Format: 123)"
            placeholder="CVV"
            pattern="(\d{3}([\-]\d{2})?)*"
            maxlength="3"
            size="3"
            id="card-verification">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="promo-code" class="sr-only">Promo Code</label>
          <input
            @input="handleChange"
            v-model="promoCode"
            name="promoCode"
            class="input-border"
            type="text"
            placeholder="Promo Code"
            id="promo-code">
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Payment',
  components: {
    VueTelInput,
  },
  props: {
    errors: Object
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      birthdayMonth: '',
      birthdayDay: '',
      birthdayYear: '',
      zipCode:'',
      email: '',
      telephone: '',
      receivePromoEmail: false,
      arriveEarlyPromise: false,
      cardNumber: '',
      expirationMonth: 'MM',
      expirationYear: 'YY',
      cvv: '',
      promoCode: '',
      blurred: {
        firstName: false,
        lastName: false,
        birthdayMonth: false,
        birthdayDay: false,
        birthdayYear: false,
        zipCode:false,
        email: false,
        telephone: false,
        arriveEarlyPromise: false,
        cardNumber: false,
        expirationMonth: false,
        expirationYear: false,
        cvv: false,
      },
      selectMonths: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    }
  },
  computed: {
    getSelectYears() {
      const currentYear = new Date().getFullYear();
      // Get last two digits of year as a Number
      const lastTwoDigits = Number(currentYear.toString().substr(2));
      // Create new array starting with current year + 20 years (21 for index based)
      const yearsArray = [...Array(21).keys()].map((num) => (lastTwoDigits + num));
      return yearsArray;
    }
  },
  watch: {
      receivePromoEmail(value) {
        this.emitPaymentChange('receivePromoEmail', value);
      },
      arriveEarlyPromise(value) {
        this.emitPaymentChange('arriveEarlyPromise', value);
      }
  },
  methods: {
    emitPaymentChange(name, value) {
      this.$emit('onPaymentChange', {
        name: name,
        value: value
      });
    },
    handleBlur(event) {
      this.blurred = {
        ...this.blurred,
        [event.target.name]: true
      };
    },
    handleNumberChange(event) {
      const name = event.target.name;
      const numValue = event.target.value.replace(/\D/g, '');
      this[name] = numValue;
      this.emitPaymentChange(name, numValue);
    },
    handleChange(event) {
      this.emitPaymentChange(event.target.name, event.target.value);
    },
    setAllBlurred() {
      for (const key in this.blurred) {
        this.blurred[key] = true;
      }
    },
    onInput(value) {
      console.log({ value });
    },
  }
}

</script>
