<template>
  <div class="packages__item">
    <label :for="`visitor-${visitorIndex + 1}`" class="packages__label tour-tool__label">Visitor #{{ visitorIndex + 1 }}</label>
    <span class="select-container select-container--full">
      <select
        @blur="handleBlur"
        @change="onChange"
        v-model="selectedPackage"
        :name="`visitor-${visitorIndex + 1}`"
        :id="`visitor-${visitorIndex + 1}`"
        class="u-appearance-none"
        :class="{ 'is-error': blurred == true && selectedPackage == 'Choose a package...' }"
        :visitorIndex="visitorIndex"
      >
        <option
          value="Choose a package..." disabled selected>Choose a package...</option>
        <option
          v-for="(packageItem, packageIndex) in packages"
          :value="packageValue(packageItem)"
        >{{ packageValue(packageItem) }}</option>
      </select>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PackagesItem',
  props: {
    visitorIndex: Number,
    blurred: false
  },
  data() {
    return {
      packages: [
        {
          price: 10,
          description: 'comes with a 16oz glass that will be filled once'
        },
        {
          price: 20,
          description: 'comes with a 16oz glass that will be filled twice and a souvenir'
        },
        {
          price: 30,
          description: 'comes with a 16oz glass that will be filled three times and a souvenir'
        },
      ],
      selectedPackage: 'Choose a package...',
    }
  },
  methods: {
    handleBlur() {
      this.$emit('handleblur');
    },
    packageValue(item) {
      return `$${item.price} package - ${item.description}`;
    },
    onChange(event) {
      // SelectedPackage index
      const selectedIndex = event.target.selectedIndex - 1; // Subtract "choose your package <option>"
      const selectedPackageObj = this.packages[selectedIndex];
      const visitorIndex = this.visitorIndex;

      this.$emit('onPackageChange', {
        visitorIndex,
        selectedPackageObj
      });
    }
  }
}
</script>
