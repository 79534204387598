<template>
  <div class="datepicker mt-3">
    <vc-date-picker
      v-if="tourDates.length"
      ref="datepicker"
      @input="onChange"
      v-model="selectedValue"
      :masks="masks"
      :available-dates="availableDates"
      :attributes="attributes"
      @dayclick="onDayClick"
    >
      <template v-slot="{ inputValue }">
        <div>
          <button
            aria-label="Select a date..."
            :aria-expanded="isExpanded ? 'true' : 'false'"
            class="datepicker__button"
            :class="{ 'is-error': blurred === true && selectedValue === null }"
            @click.prevent="toggleDatePicker"
            @blur="handleBlur"
          >
            <input
              :value="inputValue"
              class="u-appearance-none"
              readonly
              tabindex="-1"
              id="date"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              class="svg-calendar w-4 h-4 fill-current"
            >
              <path
                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
              ></path>
            </svg>
          </button>
        </div>
      </template>
    </vc-date-picker>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    blurred: Boolean,
    tourDates: Array,
    selectedDate: Date
  },
  data() {
    return {
      selectedValue: null,
      masks: {
        input: 'MMMM DD, YYYY',
      },
      isExpanded: false
    }
  },
  computed: {
    attributes() {
      return this.tourDates.map(dateObj => {
        return {
          highlight: {
            color: 'transparent',
            fillMode: 'light',
            contentClass: 'available'
          },
          dates: new Date(dateObj.date.year, dateObj.date.month, dateObj.date.day)
        }
      });
    },
    availableDates() {
      return this.tourDates.map(dateObj => {
        const formattedDate = new Date(dateObj.date.year, dateObj.date.month, dateObj.date.day);
        return {
          start: formattedDate,
          end: formattedDate
        }
      });
    }
  },
  mounted() {
    if (this.selectedDate) {
      this.selectedValue = this.selectedDate;
    }
  },
  methods: {
    handleBlur() {
      setTimeout(() => {
        this.$emit('handleblur', 'date');
        this.isExpanded = false;
      }, 100); // delay blur - blur is being called before on change
    },
    onChange(value) {
      this.$emit('onDateChange', this.selectedValue);
    },
    toggleDatePicker() {
      this.isExpanded = !this.isExpanded;
      this.$refs.datepicker.togglePopover({ placement: 'bottom' });
    },
    onDayClick(value) {
      // console.log(value)
    }
  }
}
</script>
  