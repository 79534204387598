import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { tourDates, employeeTourDates, addEmployeeTourDates } from '../data/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    maxGroupSize: 14,
    tourDates: [],
    employeeTourDates: [],
    isEmployee: isEmployee // isEmployee - global variable set in .php template & block
  },
  mutations: {
    addTourDates: (state, data) => {
      state.maxGroupSize = data.maxGroupSize;
      state.tourDates = data.dates;
    },
    addEmployeeTourDates: (state, data) => {
        state.maxGroupSize = data.maxGroupSize;
      state.employeeTourDates = data.dates;
    },
  },
  actions: {
    fetchTourDates: ({ commit }) => {
      /**
       * /api/get-tour-dates
       */
      // commit('addTourDates', tourDates);

      return (
        axios.get('/wp-json/shiner/v1/get-tour-dates/?cachebust=' + Date.now())
          .then(response => {
            commit('addTourDates', response.data);
            return response;
          })
      );
    },
    fetchEmployeeTourDates: ({ commit }) => {
      /**
       * /api/employee/get-tour-dates
       */

      return (
        axios.get('/wp-json/shiner/v1/employee/get-tour-dates')
          .then(response => {
            commit('addEmployeeTourDates', response.data);
            return response;
          })
      );
    },
    createTicketPurchase: ({ commit }, params) => {
      /**
       * /api/create-ticket-purchase
       */

      // console.log(JSON.stringify(params, null, 2));
      return (
        axios.post('/wp-json/shiner/v1/create-ticket-purchase', params)
      );
    },
    addVisitors: ({ commit }, params) => {
      /**
       * Post /api/employee/add-visitors (pass receiptNumber)
       * Results should return `employee-tour-dates` with the new visitor added
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/add-visitor', params)
      );
    },
    deleteVisitor: ({ commit }, params) => {
      /**
       * Post /api/employee/delete-visitor (pass receiptNumber)
       * Results should return `employee-tour-dates` without the visitor deleted
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/delete-visitor', params)
      );
    },
    moveVisitor: ({ commit }, params) => {
      /**
       * Put /api/employee/move-visitor (pass params)
       * Results should return `employee-tour-dates` with visitor moved to a new date
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/move-visitor', params)
      );
    },
    addEvent: ({ commit }, params) => {
      /**
       * Post /api/employee/add-event (pass params)
       * Results should return `employee-tour-dates` without the new event added to the date and time
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/add-event', params)
      );
    },
    moveEvent: ({ commit }, params) => {
      /**
       * Post /api/employee/move-event (pass params)
       * Results should return `employee-tour-dates` without the new event added to the date and time
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/move-event', params)
      );
    },
    deleteEvent: ({ commit }, params) => {
      /**
       * Post /api/employee/delete-event (pass params)
       * Results should return `employee-tour-dates` without the new event added to the date and time
      */

      return (
        axios.post('/wp-json/shiner/v1/employee/delete-event', params)
      );
    },
  },
})
