<template>
  <form class="employee-form" @submit.prevent="onSubmit">
    <p class="mb-3 text-center">{{ heading }}</p>

    <div v-if="submissionError && !loading" class="mb-4">
      <p class="tour-tool__label u-color-red">
        {{
          submissionError.status === 'unavailable'
          ? 'Your tour times are no longer available'
          : 'There was an error'
        }}
      </p>
      <ul class="tour-tool__description u-color-brown">
        <li v-for="message in submissionError.messages">{{message}}</li>
      </ul>
    </div>

    <div class="mt-3">
      <label for="event-title" class="tour-tool__description mb-2">Event Title</label>
      <input
        @blur="handleBlur"
        v-model="title"
        name="title"
        type="text"
        class="employee-form__input c-modal__input"
        :class="{ 'is-error': error.title }"
        id="event-title"
      >
    </div>
    <!-- <label for="event-notes" class="tour-tool__description mb-2">Notes</label>
    <textarea
      @blur="handleBlur"
      v-model="eventSubtitle"
      name="eventSubtitle"
      type="text"
      class="employee-form__input employee-form__textarea"
      :class="{ 'is-error': blurred.eventSubtitle == true && eventSubtitle.length < 1 }"
      id="event-notes"
    ></textarea> -->
    <div class="mt-3">
      <label for="employee-add-firstname" class="tour-tool__description mb-2">First Name</label>
      <input
        @blur="handleBlur"
        v-model="firstname"
        name="firstname"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.firstname }"
        id="employee-add-firstname"
      >
    </div>
    <div class="mt-3">
      <label for="employee-add-lastname" class="tour-tool__description mb-2">Last Name</label>
      <input
        @blur="handleBlur"
        v-model="lastname"
        name="lastname"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.lastname }"
        id="employee-add-lastname"
      >
    </div>
    <div class="mt-3">
      <label for="employee-add-phone" class="tour-tool__description mb-2">Phone Number</label>
      <input
        @blur="handleBlur"
        v-model="phone"
        name="phone"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.phone }"
        id="employee-add-phone"
      >
    </div>
    <div class="mt-3">
      <label for="employee-add-reservations" class="tour-tool__description mb-2">Group Size</label>
      <input
        @input="handleNumberChange"
        @blur="handleBlur"
        v-model="reservations"
        name="reservations"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.reservations }"
        id="employee-add-reservations"
      >
    </div>

    <p v-if="loading" class="text-center mt-4 mb-3">Loading...</p>

    <div v-else class="d-flex">
      <button type="button" @click.prevent="close" class="button button-crud mt-4">Cancel</button>
      <button type="submit" class="button button-crud mt-4">Add</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'AddEventForm',
  props: {
    heading: {
      type: String,
      default: 'Add Event'
    },
    isFullDayEvent: Boolean,
    selectedDate: Date,
    selectedTime: Object,
    selectedDateIndex: Number,
    selectedTimeIndex: Number
  },
  data() {
    return {
      title: '',
      firstname: '',
      lastname: '',
      phone: '',
      reservations: '',
      error: {
        title: false,
        firstname: false,
        lastname: false,
        phone: false,
        reservations: false
      },
      submissionError: null,
      loading: false
    };
  },
  methods: {
    handleBlur(event) {
      this.validate(event.target.name);
    },
    handleNumberChange(event) {
      const name = event.target.name;
      const numValue = event.target.value.replace(/\D/g, '');
      this[name] = numValue;
    },
    close() {
      this.$emit('onClose');
    },
    validate(name) {
      let isValid = true;
      
      if (['title', 'firstname', 'lastname'].includes(name)) {
        isValid = /\S/.test(this[name]);
      } else if (name === 'phone') {
        isValid = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/.test(this[name]);
      } else if (name === 'reservations') {
        isValid = /^\d+$/.test(this[name]);
      }
      
      this.error[name] = !isValid;
      
      return isValid;
    },
    validateAll() {
      return ['title', 'firstname', 'lastname', 'phone', 'reservations'].reduce((result, name) => {
        return this.validate(name) && result;
      }, true);
    },
    onSubmit() {
      if (this.validateAll()) {
        this.addEvent();
      } else {
        const messages = {
          title: 'Please provide a valid event title.',
          firstname: 'Please provide a valid first name.',
          lastname: 'Please provide a valid last name.',
          phone: 'Please provide a valid phone number.',
          reservations: 'Please provide a group size.'
        };
        
        this.submissionError = {
          messages: Object.keys(this.error).filter(key => this.error[key]).map(key => messages[key])
        };
      }
    },
    async addEvent() {
      this.loading = true;
      
      const params = {
        title: this.title,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone.replace(/^\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/g, '($1) $2-$3'),
        date: this.$formatDate(this.selectedDate),
        time: this.isFullDayEvent ? undefined : this.selectedTime.time,
        reservations: Number(this.reservations)
      };
      
      const response = await this.$store.dispatch('addEvent', params);
      
      if (response.data.status === 'completed') {
        await this.$store.dispatch('fetchEmployeeTourDates');
        // close on success
        this.close();
      } else {
        this.submissionError = response.data;
      }
      
      this.loading = false;
    }
  }
}
</script>
