<template>
  <div class="packages">
    <p class="tour-tool__description u-color-red mb-2 d-none">Please choose a package for each person.</p>
    <template v-for="(visitor, visitorIndex) in groupSize">
      <PackagesItem
        :visitorIndex="visitorIndex"
        @onPackageChange="onPackageChange"
        ref="packagesItems"
        :blurred="blurred[visitorIndex] == true"
        @handleblur="handleblur"
      />
    </template>
  </div>
</template>

<script>
import PackagesItem from './PackagesItem';

export default {
  name: 'Packages',
  components: {
    PackagesItem
  },
  props: {
    groupSize: Number,
  },
  data() {
    return {
      selectedPackages: [],
      blurred: []
    }
  },
  watch: {
    groupSize(size) {
      // Update selectedPackages array if group size is changed
      if (size < this.selectedPackages.length) {
        this.selectedPackages.length = size;
        this.$emit('onPackageChange', this.selectedPackages);
      }
    }
  },
  methods: {
    handleblur() {
      this.blurred.push(true);
    },
    setAllBlurred() {
      this.blurred = this.$refs.packagesItems.map((item) => {
        return true;
      })
    },
    packageValue(item) {
      return `$${item.price} package - ${item.description}`;
    },
    onPackageChange(obj) {
      this.selectedPackages[obj.visitorIndex] = obj.selectedPackageObj;
      this.$emit('onPackageChange', this.selectedPackages);
    }
  }
}
</script>
