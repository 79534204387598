<template>
  <div class="all-day mt-5">
    <div class="d-flex justify-content-between align-items-end">
      <p class="all-day__heading mb-0">No tours booked for the day</p>
      <button @click="active = true" class="button button-add">Add All-Day Event</button>
    </div>

    <Modal :active="active" @onClose="active = false">
      <AddEventForm
        isFullDayEvent
        heading="Add All-Day Event"
        :selectedDate="selectedDate"
        :selectedDateIndex="selectedDateIndex"
        @onClose="active = false"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal';
import AddEventForm from './AddEventForm';

export default {
  name: 'AddFullDayEvent',
  components: {
    Modal,
    AddEventForm
  },
  props: {
    selectedDate: Date,
    selectedDateIndex: Number
  },
  data() {
    return {
      active: false
    }
  }
}
</script>
