<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <EmployeeForm v-if="isEmployee" />
          <CustomerForm v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import CustomerForm from './components/CustomerForm';
import EmployeeForm from './components/EmployeeForm';

function pad(number) {
  return ('0' + String(number)).slice(-2);
}

Vue.prototype.$formatDate = function (date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  
  return year + '-' + pad(month) + '-' + pad(day);
};

Vue.prototype.$formatTime = function (time) {
  const hour = parseInt(time, 10);
  const displayHour = String((hour === 0 || hour === 12) ? 12 : hour % 12);
  const minutes = time.replace(/^\d{2}:/, '');
  const period = (hour < 12 ? 'am' : 'pm');
  return displayHour + ':' + minutes + period;
};

export default {
  name: 'App',
  components: {
    EmployeeForm,
    CustomerForm
  },
  computed: {
    ...mapState(['isEmployee']),
  }
}
</script>
