<template>
  <div class="tour-tool tour-tool--employee">
    <h1>Shiner Brewery Tour Booking</h1>
    <div class="admin-link" @click="returnToAdmin">
        <svg data-v-19b6cf78="" width="26px" height="26px" viewBox="0 -1 16 34" class="vc-svg-icon">
          <path data-v-19b6cf78="" d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z"></path>
        </svg>
        <span>Admin</span>
    </div>

      <transition name="fade-employee">
        <div v-if="isCalendarView">
          <label for="group-size" class="tour-tool__availability tour-tool__description">Filter dates by availability: </label>
          <GroupSize
            @onSizeChange="onSizeChange"
            defaultOption="All Dates"
            :defaultDisabled="false"
            :selectedGroupSize="groupSize"
          />

          <Calendar
            @onDateChange="onDateChange"
            :tourDates="filteredDates"
            :selectedDate="date"
          />
        </div>
      </transition>

      <transition name="fade-employee">
        <div v-if="isVisitorView">
          <div class="d-flex">
            <button
              type="button" class="button button-back"
              @click="showCalendarView"
            >Calendar view</button>
          </div>

          <DatePicker
            @onDateChange="onDateChange"
            :tourDates="filteredDates"
            :selectedDate="date"
          />

          <div class="mt-5" v-if="selectedDateObj.hasFullDayEvent == false">
            <div v-if="!isOpenSchedule" class="mb-5">
              <button class="button button-visitors" @click="toggleSummaryView">
                {{ isSummaryView ? 'Show Detail View' : 'Show Summary View' }}
              </button>
            </div>

            <TimePicker
              v-if="!isSummaryView"
              @onTimeChange="onTimeChange"
              canFocusAll
              :groupSize="groupSize"
              :times="selectedDateObj.times"
              ref="timePicker"
              :selectedTime="selectedTimeObj"
            />
          </div>

          <SpecialEvent
            v-if="selectedDateObj.hasFullDayEvent == true"
            :specialEvent="selectedDateObj.specialEvent"
            :selectedDate="date"
            :selectedTime="selectedTimeObj"
            eventTypeText="All-Day Event:"
            show-edit-controls
          />

          <AddFullDayEvent
            v-if="isOpenSchedule && selectedDateObj.hasFullDayEvent == false"
            :selectedDate="date"
            :selectedDateIndex="selectedDateIndex"
          />

          <div v-if="selectedDateObj.hasFullDayEvent == false">
            <transition name="fade-employee">
              <SummaryView v-if="isSummaryView" :selectedDateObj="selectedDateObj" @onTimeChange="onTimeChange" />

              <Visitors
                v-if="selectedTimeObj !== null && selectedTimeObj.hasSpecialEvent === false"
                :selectedDate="date"
                :selectedTime="selectedTimeObj"
                :selectedDateIndex="selectedDateIndex"
                :selectedTimeIndex="selectedTimeIndex"
                @onShouldUpdate="shouldUpdate"
              />
              <SpecialEvent
                v-else-if="selectedTimeObj !== null && selectedTimeObj.hasSpecialEvent === true"
                :specialEvent="selectedTimeObj.specialEvent"
                :selectedDate="date"
                :selectedTime="selectedTimeObj"
                eventTypeText="Blocked Time:"
                show-edit-controls
              />
            </transition>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import GroupSize from './GroupSize';
  import Calendar from './Calendar';
  import DatePicker from './DatePicker';
  import TimePicker from './TimePicker';
  import SpecialEvent from './SpecialEvent';
  import Visitors from './Visitors';
  import AddFullDayEvent from './AddFullDayEvent';
  import SummaryView from './SummaryView';

  export default {
    name: 'EmployeeForm',
    components: {
      GroupSize,
      Calendar,
      DatePicker,
      TimePicker,
      SpecialEvent,
      Visitors,
      AddFullDayEvent,
      SummaryView
    },
    data() {
      return {
        selectedDateIndex: -1,
        selectedTimeIndex: -1,
        filteredDates: [],
        groupSize: null,
        date: null,
        selectedTimeObj: null,
        // selectedDateObj: {},
        isCalendarView: true,
        isVisitorView: false,
        isSummaryView: false,
      }
    },
    computed: {
      ...mapState(['tourDates', 'employeeTourDates']),
      selectedDateObj() {
        return this.filteredDates[this.selectedDateIndex];
      },
      isOpenSchedule() {
        let count = 0;

        for (let i = 0; i < this.selectedDateObj.times.length; i++) {
          count += (
            this.selectedDateObj.times[i].hasSpecialEvent
            ? 1
            : this.selectedDateObj.times[i].visitors.length
          );
        }
        return count === 0 ? true : false;
      },
      masterList() {
        return this.selectedDateObj.times;
      }
    },
    watch: {
      employeeTourDates(val) {
        /*
          Must reset groupsize filter
          If the user filters by availability at the start
          and a visitor is added to a date+time AND the ticketsAvailable is less
          than what was originally filtered then it should no longer technically be available to "view"
        */
        this.groupSize = null;

        this.filterTourDates();
        // Update selected time object
        if (this.selectedTimeObj !== null) {
          this.selectedTimeObj = this.filteredDates[this.selectedDateIndex].times[this.selectedTimeIndex];
        }
      }
    },
    mounted() {
      this.addFilterDates();
    },
    methods: {
      async addFilterDates() {
        await this.$store.dispatch('fetchEmployeeTourDates');
        this.filteredDates = [
          ...this.employeeTourDates
        ];
      },
      filterTourDates() {
        if (this.groupSize === null) {
          return this.filteredDates = [
            ...this.employeeTourDates
          ];
        }

        const groupSize = this.groupSize;

        const filteredDates = this.employeeTourDates.filter((item) => {
          let condition = false;
          item.times.forEach((time) => {
            if (groupSize <= time.ticketsAvailable) return condition = true;
          });
          return condition;
        });

        this.filteredDates = [
          ...filteredDates
        ];
      },
      getDateIndex(date) {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const dateIndex = this.filteredDates.findIndex((item) => {
          return item.date.year == year && item.date.month == month && item.date.day == day;
        })

        return dateIndex;

        // const filteredDate = this.filteredDates.find((item) => {
        //   return item.date.year == year && item.date.month == month && item.date.day == day;
        // });
      },
      getTimeIndex(time) {
        if (!time) {
          return -1;
        }

        const timeIndex = this.filteredDates[this.selectedDateIndex].times.findIndex((item) => {
          return item.time == time.time;
        })
        return timeIndex;
      },
      onSizeChange(size) {
        this.groupSize = size;
        this.filterTourDates();
        // Clear date and time
        this.date = null;
        this.selectedTimeObj = null;
        if (this.$refs.timePicker) {
          this.$refs.timePicker.clearSelectedTime();
        }
      },
      onDateChange(date) {
        this.date = date;
        // Go to next view
        if (date !== null) {
          // Clear selectedTimeObj
          this.selectedTimeObj = null;
          if (this.$refs.timePicker) {
            this.$refs.timePicker.clearSelectedTime();
          }

          // Get date object
          // this.selectedDateObj = this.filterSelectedDate(date);

          // Get date index
          this.selectedDateIndex = this.getDateIndex(date);

          this.isCalendarView = false;
          setTimeout(() => this.isVisitorView = true, 250);
        }
      },
      onTimeChange(time, showDetail = false) {
        this.selectedTimeObj = time;
        this.selectedTimeIndex = this.getTimeIndex(time);

        if (showDetail) {
          this.isSummaryView = false;
        }
      },
      showCalendarView() {
        this.isVisitorView = false;
        setTimeout(() => this.isCalendarView = true, 250);
      },
      toggleSummaryView() {
        this.isSummaryView = !this.isSummaryView;
      },
      returnToAdmin() {
        location.href="/wp-admin";
      },
    }
  }
</script>
