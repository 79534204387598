<template>
  <div class="visitors mt-5">
    <div class="d-flex justify-content-between align-items-end">
      <p class="visitors__heading mb-0">{{ getVisitorsCount }} Visitors @
        <span class="visitors__time">{{ $formatTime(selectedTime.time) }}</span>
      </p>
      <div class="d-flex">
        <button v-if="getVisitorsCount !== 12" @click="activeAdd = true" class="button button-visitors">
          <VisitorsIcon />
          Add Visitors</button>
        <button
          v-if="getVisitorsCount === 0"
          @click="activeEvent = true"
          class="button button-add button-event"
        >Add Event</button>
      </div>
    </div>
    <div class="visitors__items mt-3 mb-5">
      <div v-for="(visitor) in selectedTime.visitors" class="visitors__row row text-left">
        <div class="col-6 col-md-9 visitors__col">
          <p class="tour-tool__description mb-2">{{ visitor.name.first }} {{ visitor.name.last }}</p>
          <p class="tour-tool__description mb-2">Party of {{ visitor.quantity }}</p>
          <p class="tour-tool__description mb-2">Receipt #: {{ visitor.order_id }}</p>
          <p class="tour-tool__description mb-2">Email: {{ visitor.email }}</p>
          <p class="tour-tool__description mb-2">Phone: {{ visitor.phone }}</p>
        </div>
        <!-- <div class="col-4 col-md-3 visitors__col">
          <p v-for="(packageItem, index) in visitor.packages" class="tour-tool__description mb-2">
            Visitor #{{ index + 1 }} - ${{ packageItem.price }} Package
          </p>
        </div> -->
        <div class="col-6 col-md-3 visitors__col text-center">
          <button class="button button-crud mb-2" @click="openDelete(visitor)">Delete</button>
          <button class="button button-crud mb-2" @click="openMove(visitor)">Move</button>
        </div>
      </div>
    </div>

    <Modal :active="activeAdd" @onClose="closeAdd">
      <AddVisitorForm :date="selectedDate" :time="selectedTime.time" @onClose="closeAdd" :availableGroupSize="availableGroupSize" />
    </Modal>

    <Modal :active="activeEvent" @onClose="closeEvent">
      <AddEventForm
        :selectedDate="selectedDate"
        :selectedTime="selectedTime"
        :selectedDateIndex="selectedDateIndex"
        :selectedTimeIndex="selectedTimeIndex"
        @onClose="closeEvent"
      />
    </Modal>

    <Modal :active="activeDelete" @onClose="closeDelete">
      <DeleteVisitorForm :visitor="visitorInEdit" @onClose="closeDelete" />
    </Modal>

    <Modal :active="activeMove" @onClose="closeMove" className="tool-modal--move">
      <MoveVisitorForm
        :currentDate="selectedDate"
        :currentTime="selectedTime"
        :visitor="visitorInEdit"
        @onClose="closeMove"
      />
    </Modal>

  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Modal from './Modal';
  import AddVisitorForm from './AddVisitorForm';
  import AddEventForm from './AddEventForm';
  import DeleteVisitorForm from './DeleteVisitorForm';
  import MoveVisitorForm from './MoveVisitorForm';
  import VisitorsIcon from './VisitorsIcon';

  export default {
    name: 'Visitors',
    components: {
      Modal,
      AddVisitorForm,
      AddEventForm,
      DeleteVisitorForm,
      VisitorsIcon,
      MoveVisitorForm
    },
    props: {
      selectedDate: Date,
      selectedTime: Object,
      selectedDateIndex: Number,
      selectedTimeIndex: Number
    },
    data() {
      return {
        activeAdd: false,
        activeEvent: false,
        activeDelete: false,
        activeMove: false,
        visitorInEdit: null,
        visitors: []
      }
    },
    computed: {
      ...mapState(['maxGroupSize']),
      getVisitorsCount() {
        let count = 0;

        for (let i = 0; i < this.selectedTime.visitors.length; i++) {
          for (let j = 0; j < parseInt(this.selectedTime.visitors[i].quantity, 10); j++) {
            count += 1;
          }
        }
        return count;
      },
      availableGroupSize() {
        return this.maxGroupSize - this.getVisitorsCount;
      }
    },
    methods: {
      closeAdd() {
        this.activeAdd = false;
      },
      closeEvent() {
        this.activeEvent = false;
      },
      openDelete(visitor) {
        this.visitorInEdit = visitor;
        this.$nextTick(() => this.activeDelete = true);
      },
      openMove(visitor) {
        this.visitorInEdit = visitor;
        this.$nextTick(() => this.activeMove = true);
      },
      closeDelete() {
        this.activeDelete = false;
        this.visitorInEdit = null;
      },
      closeMove() {
        this.activeMove = false;
        this.visitorInEdit = null;
      },
      handleBlur(event) {
        this.blurred = {
          ...this.blurred,
          [event.target.name]: true
        };
      }
    }
  }
</script>
