<template>
  <div class="time-picker" :class="className">
    <!-- <div
      class="time-picker__item"
      :class="{'is-unavailable': time.ticketsAvailable < groupSize }"
      v-for="(time) in times"
    >
      <label :for="time.hour + time.period" class="sr-only"></label>
      <input
        v-model="selectedTime"
        v-if="time.ticketsAvailable >= groupSize || canFocusAll"
        @blur="handleBlur"
        :value="time"
        name="time"
        type="radio" 
        :id="time.hour + time.period"
        class="time-picker__input u-absolute-full"
        :class="{ 'is-error': blurred === true && selectedTime === null }"
      >
      <div class="time-picker__hour">{{ time.hour }}</div>
      <div class="time-picker__period">{{ time.period }}</div>
      <span class="time-picker__checkmark"></span>
    </div> -->
    
    <div class="select-container">
      <select
        @blur="handleBlur"
        v-model="selectedTime"
        id="tour-time"
        name="time"
        class="u-appearance-none"
        :class="{ 'is-error': blurred === true && selectedTime === null }"
      >
        <option :value="null" disabled="disabled">Please select a time...</option>
        <option v-for="time in times" :value="time" :disabled="isDisabled(time)">
          {{ $formatTime(time.time) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TimePicker',
  props: {
    blurred: Boolean,
    canFocusAll: Boolean,
    className: String,
    groupSize: Number,
    times: Array,
    selectedTime: Object
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isEmployee']),
  },
  watch: {
    selectedTime(value) {
      // if (value !== null) {
        this.$emit('onTimeChange', this.selectedTime);
      // }
    }
  },
  methods: {
    handleBlur() {
      this.$emit('handleblur', 'time');
    },
    clearSelectedTime() {
      this.selectedTime = null;
    },
    isAvailable() {
      if (this.isEmployee === true) {}
    },
    isDisabled(time) {
      if (this.canFocusAll) {
        // Disable times that have no tickets available but no visitors and no special events booked
        // to handle Sunday morning times
        return !(time.ticketsAvailable > 0 || time.visitors.length > 0 || time.hasSpecialEvent);
      }
      return time.ticketsAvailable < this.groupSize;
    }
  }
}
</script>
