<template>
  <div class="calendar datepicker mt-4">
    <vc-date-picker
      ref="calendar"
      @input="onChange"
      v-model="selectedValue"
      :available-dates="availableDates"
      :attributes="attributes"
    ></vc-date-picker>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    tourDates: Array,
    selectedDate: null
  },
  data() {
    return {
      selectedValue: null
    }
  },
  computed: {
    attributes() {
      return this.tourDates.map(dateObj => {
        return {
          highlight: {
            color: 'transparent',
            fillMode: 'light',
            contentClass: 'available'
          },
          dates: new Date(dateObj.date.year, dateObj.date.month, dateObj.date.day)
        }
      });
    },
    availableDates() {
      return this.tourDates.map(dateObj => {
        const formattedDate = new Date(dateObj.date.year, dateObj.date.month, dateObj.date.day);
        return {
          start: formattedDate,
          end: formattedDate
        }
      });
    }
  },
  mounted() {
    if (this.selectedDate) {
      this.selectedValue = this.selectedDate;
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onDateChange', this.selectedValue);
    }
  }
}
</script>
