<template>
  <form class="tour-tool customer-form" @submit.prevent="handleSubmit">

    <label for="group-size" class="tour-tool__label">How many people in your group?</label>
    <GroupSize
      @onSizeChange="onSizeChange"
      @handleblur="handleBlur"
      :blurred="blurred.groupSize"
      defaultOption="Please select..."
    />

    <transition name="fade">
      <div v-if="tourInEdit.groupSize">
        <label for="date" class="tour-tool__label u-padding-top">Choose your date</label>
        <DatePicker
          @onDateChange="onDateChange"
          @handleblur="handleBlur"
          :blurred="blurred.date"
          :tourDates="filteredDates"
        />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="tourInEdit.date">
        <!-- <p class="tour-tool__label u-padding-top">Choose your time</p> -->
        <label for="tour-time" class="tour-tool__label u-padding-top">Choose your time</label>
        <TimePicker
          @onTimeChange="onTimeChange"
          @handleblur="handleBlur"
          :blurred="blurred.time"
          :groupSize="tourInEdit.groupSize"
          :times="selectedDatedTimes"
          ref="timePicker"
          :selectedTime="tourInEdit.time"
        />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showPackages">
        <p class="tour-tool__label u-padding-top">Choose a package for each person</p>
        <Packages
          @onPackageChange="onPackageChange"
          :groupSize="tourInEdit.groupSize"
          :blurred="blurred.packages"
          ref="package"
        />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="submissionError">
        <p class="tour-tool__label u-padding-top u-color-red">
          {{
            submissionError.status === 'unavailable'
            ? 'Your tour times are no longer available'
            : 'There was an error'
          }}
        </p>
        <ul class="tour-tool__description u-color-brown">
          <li v-for="message in submissionError.messages">{{message}}</li>
        </ul>
      </div>
    </transition>

    <transition name="fade">
      <div v-show="showPayment">
        <p class="tour-tool__label u-padding-top">Your Contact Info</p>
        <Payment @onPaymentChange="onPaymentChange" :errors="paymentErrors || {}" ref="payment" />

        <div class="tour-tool__submit-container mt-4">
          <button type="submit" class="button" :disabled="isSubmitting">Complete Reservation</button>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import GroupSize from './GroupSize';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import Packages from './Packages';
import Payment from './Payment';

export default {
  name: 'CustomerForm',
  components: {
    GroupSize,
    DatePicker,
    TimePicker,
    Packages,
    Payment
  },
  data() {
    return {
      filteredDates: [],
      selectedDatedTimes: null,
      tourInEdit: {
        groupSize: null,
        date: null,
        time: null,
        packages: []
      },
      payment: {
        firstName: '',
        lastName: '',
        birthdayMonth: '',
        birthdayDay: '',
        birthdayYear: '',
        zipCode:'',
        email: '',
        telephone: '',
        receivePromoEmail: false,
        arriveEarlyPromise: false,
        cardNumber: '',
        expirationMonth: 'MM',
        expirationYear: 'YY',
        cvv: '',
        promoCode: false,
      },
      blurred: {
        groupSize: false,
        date: false,
        time: false,
        packages: false
      },
      submissionError: null,
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState(['tourDates']),
    showPayment() {
      return this.tourInEdit.groupSize && this.tourInEdit.time && this.tourInEdit.time.time;
      // return this.tourInEdit.groupSize == this.tourInEdit.packages.length;
    },
    showPackages() {
      return false;
      // return this.tourInEdit.groupSize && this.tourInEdit.time && this.tourInEdit.time.hour;
    }
  },
  mounted() {
    this.$store.dispatch('fetchTourDates');
  },
  methods: {
    filterTourDates() {
      const groupSize = this.tourInEdit.groupSize;

      const filteredDates = this.tourDates.filter((item) => {
        let condition = false;
        item.times.forEach((time) => {
          if (groupSize <= time.ticketsAvailable) return condition = true;
        });
        return condition;
      });

      this.filteredDates = [
        ...filteredDates
      ];
    },
    filterSelectedDate(date) {
      if (!date) {
        return;
      }
      
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      const selectedDate = this.filteredDates.find((item) => {
        return item.date.year == year && item.date.month == month && item.date.day == day;
      });

      this.selectedDatedTimes = selectedDate.times;
    },
    handleBlur(field) {
      this.blurred = {
        ...this.blurred,
        [field]: true
      };
    },
    onSizeChange(size) {
      this.tourInEdit.groupSize = size;
      this.filterTourDates();
      // Clear time and packages
      this.tourInEdit.time = null;
      this.tourInEdit.packages = [];
      if (this.$refs.timePicker) {
        this.$refs.timePicker.clearSelectedTime();
      }
    },
    onDateChange(date) {
      this.tourInEdit.date = date;
      // Clear time and packages
      this.tourInEdit.time = null;
      this.tourInEdit.packages = [];
      if (this.$refs.timePicker) {
        this.$refs.timePicker.clearSelectedTime();
      }

      this.filterSelectedDate(date);
    },
    onTimeChange(time) {
      this.tourInEdit.time = time;
    },
    onPackageChange(packages) {
      this.tourInEdit.packages = [
        ...packages
      ];
    },
    onPaymentChange(input) {
      this.payment = {
        ...this.payment,
        [input.name]: input.value
      }
    },
    handleSubmit(event) {
      if (this.isValidModel()) {
        const { tourInEdit, payment } = this;
        const { date, time } = tourInEdit;

        const model = {
          firstname: payment.firstName,
          lastname: payment.lastName,
          date: this.$formatDate(date),
          time: time.time.replace(/^\d:/, '0$&'),
          reservations: tourInEdit.groupSize,
          zipcode: payment.zipCode,
          dob: `${payment.birthdayYear}-${payment.birthdayMonth}-${payment.birthdayDay}`,
          email: payment.email,
          telephone: payment.telephone.replace(/^\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/g, '($1) $2-$3'),
          receivePromoEmail: payment.receivePromoEmail
        };

        grecaptcha.ready(() => {
          grecaptcha.execute(
            '6Lf-cagaAAAAAMHlbjdvD5_VIKOI3B9E_sKQeuzf',
            { action: 'submit' }
          ).then((token) => {
            this.isSubmitting = true;

            this.$store.dispatch('createTicketPurchase', { ...model, token })
              .then(response => {
                this.isSubmitting = false;

                const { data } = response;

                if (data.status === 'completed') {
                  window.location = '/thank-you/?confirmation=' + encodeURIComponent(data.order_id);
                } else if (data.status === 'unavailable') {
                  this.$store.dispatch('fetchTourDates')
                    .then(response => {
                      this.submissionError = data;
                      this.onDateChange(this.tourInEdit.date);
                    });
                } else {
                  this.submissionError = data;
                }
              })
              .catch(() => this.isSubmitting = false);
          });
        });
      } else {
        this.setAllBlurred();
      }
    },
    isValidModel() {
      console.log(this);
      const { payment } = this;
      console.log(payment);

      const errors = {};
      const errorMessages = [];

      if (payment.firstName === '') {
        errorMessages.push('Please provide a valid first name.');
      }
      if (payment.lastName === '') {
        errorMessages.push('Please provide a valid last name.');
      }
      if (!/^(?:0[1-9]|1[012])$/.test(payment.birthdayMonth)) {
        errors.birthdayMonth = true;
      }
      if (!/^(?:0[1-9]|[12][0-9]|3[01])$/.test(payment.birthdayDay)) {
        errors.birthdayDay = true;
      }
      if (!/^[0-9]{4}$/.test(payment.birthdayYear)) {
        errors.birthdayYear = true;
      }

      const month = parseInt(payment.birthdayMonth, 10);
      const day = parseInt(payment.birthdayDay, 10);
      const year = parseInt(payment.birthdayYear, 10);
      const date = new Date();
      const testDate = new Date(year, month - 1, day);

      if (year <= 1820 || date.getTime() < testDate.getTime()) {
        errors.birthdayYear = true;
      }

      if (testDate.getMonth() !== month - 1) {
        errors.birthdayDay = true;
      }

      if (errors.birthdayMonth || errors.birthdayDay || errors.birthdayYear) {
        errorMessages.push('Please provide your date of birth.');
      }

      if (payment.zipCode.length !== 5) {
        errorMessages.push('Please provide your ZIP code.');
      }

      if (payment.email === '') {
        errorMessages.push('Please provide your email address.');
      }

      if (!/^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/.test(payment.telephone) ) {
        errorMessages.push('Please provide your phone number.');
      }
      console.log('arriveEarlyPromise',payment.arriveEarlyPromise);
      if (payment.arriveEarlyPromise === false) {
        errorMessages.push('You must agree to arrive 15 minutes early in order to book this tour.');
      }


      this.submissionError = (
        errorMessages.length === 0
        ? null
        : { messages: errorMessages }
      );
      this.paymentErrors = errors;

      return (
        this.tourInEdit.groupSize !== null
        && this.tourInEdit.date !== null
        && this.tourInEdit.time !== null
        && errorMessages.length === 0
      );

/*
      return (
        this.tourInEdit.groupSize !== null
        && this.tourInEdit.date !== null
        && this.tourInEdit.time !== null
        // && this.tourInEdit.groupSize === this.tourInEdit.packages.length
        && this.payment.firstName !== ''
        && this.payment.lastName !== ''
        && this.payment.birthdayMonth.length == 2
        && this.payment.birthdayDay.length == 2
        && this.payment.birthdayYear.length == 4
        && this.payment.zipCode.length == 5
        && this.payment.email !== ''
        && this.payment.telephone !== ''
        // && this.payment.cardNumber.length === 16
        // && this.payment.expirationMonth != 'MM'
        // && this.payment.expirationYear != 'YY'
        // && this.payment.cvv.length == 3
      );
*/
    },
    setAllBlurred() {
      for (const key in this.blurred) {
        this.blurred[key] = true;
      }
      // this.$refs.package.setAllBlurred();
      this.$refs.payment.setAllBlurred();
    }
  }
}
</script>
