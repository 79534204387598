<template>
  <form class="text-left" @submit.prevent="onSubmit">
    <p class="mb-3 text-center">Add Visitors</p>

    <div v-if="submissionError && !loading" class="mb-4">
      <p class="tour-tool__label u-color-red">
        {{
          submissionError.status === 'unavailable'
          ? 'Your tour times are no longer available'
          : 'There was an error'
        }}
      </p>
      <ul class="tour-tool__description u-color-brown">
        <li v-for="message in submissionError.messages">{{message}}</li>
      </ul>
    </div>
      
    <label for="receipt-number" class="tour-tool__description mb-2">Receipt Number</label>
    <input
      @input="handleNumberChange"
      @blur="handleBlur"
      v-model="receiptNumber"
      name="receiptNumber"
      type="text"
      class="employee-form__input"
      :class="{ 'is-error': error.receiptNumber }"
      id="receipt-number">
    <div class="mt-3">
      <label for="employee-add-firstname" class="tour-tool__description mb-2">First Name</label>
      <input
        @blur="handleBlur"
        v-model="firstname"
        name="firstname"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.firstname }"
        id="employee-add-firstname"
      >
    </div>
    <div class="mt-3">
      <label for="employee-add-lastname" class="tour-tool__description mb-2">Last Name</label>
      <input
        @blur="handleBlur"
        v-model="lastname"
        name="lastname"
        type="text"
        class="employee-form__input"
        :class="{ 'is-error': error.lastname }"
        id="employee-add-lastname"
      >
    </div>
    <div class="mt-3">
      <label for="employee-add-reservations" class="tour-tool__description mb-2">Group Size</label>
      <div>
        <GroupSize
          @onSizeChange="onSizeChange"
          @handleblur="handleBlur" 
          :blurred="error.reservations"
          defaultOption="Please select..."
          :availableGroupSize="availableGroupSize"
        />
      </div>
    </div>

    <p v-if="loading" class="text-center mt-4 mb-3">Loading...</p>

    <div v-else class="d-flex">
      <button type="button" @click.prevent="close" class="button button-crud mt-4">Cancel</button>
      <button type="submit" class="button button-crud mt-4">Add</button>
    </div>
  </form>
</template>

<script>
import GroupSize from './GroupSize';

export default {
  name: 'AddVisitorForm',
  props: {
    visitors: Array,
    date: Object,
    time: String,
    availableGroupSize: Number
  },
  components: {
    GroupSize
  },
  data() {
    return {
      receiptNumber: '',
      firstname: '',
      lastname: '',
      reservations: '',
      error: {
        receiptNumber: false,
        firstname: false,
        lastname: false,
        reservations: false,
      },
      submissionError: null,
      loading: false
    }
  },
  methods: {
    handleBlur(event) {
      // this.blurred = {
      //   ...this.blurred,
      //   [event.target.name]: true
      // };
      let name = (
        event === 'groupSize'
        ? 'reservations'
        : event.target.name
      );
      this.validate(name);
    },
    handleNumberChange(event) {
      const name = event.target.name;
      const numValue = event.target.value.replace(/\D/g, '');
      this[name] = numValue;
    },
    close() {
      this.$emit('onClose');
    },
    validate(name) {
      let isValid = true;
      
      if (name === 'receiptNumber') {
        isValid = this[name].length > 0;
      } else if (name === 'firstname' || name === 'lastname') {
        isValid = /\S/.test(this[name]);
      } else if (name === 'reservations') {
        isValid = /^\d+$/.test(this[name]);
      }
      
      this.error[name] = !isValid;
      
      return isValid;
    },
    validateAll() {
      return ['receiptNumber', 'firstname', 'lastname', 'reservations'].reduce((result, name) => {
        return this.validate(name) && result;
      }, true);
    },
    onSubmit() {
      if (this.validateAll()) {
        this.addVisitors();
      } else {
        const messages = {
          receiptNumber: 'Please enter a receipt number containing only numbers.',
          firstname: 'Please provide a valid first name.',
          lastname: 'Please provide a valid last name.',
          reservations: 'Please select a group size.'
        };
        
        this.submissionError = {
          messages: Object.keys(this.error).filter(key => this.error[key]).map(key => messages[key])
        };
      }
    },
    onSizeChange(size) {
      this.reservations = size;
    },
    async addVisitors() {
      this.loading = true;
      
      const params = {
        firstname: this.firstname,
        lastname: this.lastname,
        date: this.$formatDate(this.date),
        time: this.time,
        reservations: parseInt(this.reservations, 10),
        receiptNumber: this.receiptNumber
      };
      
      const response = await this.$store.dispatch('addVisitors', params);
      
      if (response.data.status === 'completed') {
        await this.$store.dispatch('fetchEmployeeTourDates');
        // close on success
        this.close();
      } else {
        this.submissionError = response.data;
      }
      
      this.loading = false;
    }
  }
}
</script>
