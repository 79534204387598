<template>
  <form @submit.prevent="onSubmit">
    <p class="mb-1 text-center">Select an available date and time.</p>
    <p class="mb-4 tour-tool__description text-center">(Only available options will be shown.)</p>
    <DatePicker
      @onDateChange="onDateChange"
      @handleblur="handleBlur"
      :blurred="blurred.date"
      :tourDates="filteredTourDates"
    />
    <div v-if="this.currentTime" class="mt-4 mb-3">
      <TimePicker
        @onTimeChange="onTimeChange"
        @handleblur="handleBlur"
        :blurred="blurred.time"
        :groupSize="Number(visitor.quantity)"
        :times="selectedDatedTimes"
        className="time-picker--move-visitor"
        ref="timePicker"
        :selectedTime="selectedTime"
      />
    </div>
    <div class="d-flex justify-content-center">
      <button type="button" @click.prevent="close" class="button button-crud button-crud--wide mt-4 mr-3 ml-0">Cancel</button>
      <button type="submit" class="button button-crud button-crud--wide mt-4 mr-0 ml-3">Save Changes</button>
    </div>
  </form>
</template>

<script>
/*
Only available options will be shown - this means we need to filter
the tour dates by how many total packages this person has in their group.
*/
import { mapState } from 'vuex';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';

export default {
  name: 'MoveVisitorForm',
  components: {
    DatePicker,
    TimePicker
  },
  props: {
    currentDate: Date,
    currentTime: Object,
    visitor: Object
  },
  data() {
    return {
      selectedDate: null,
      selectedDatedTimes: null,
      selectedTime: null,
      blurred: {
        date: false,
        time: false
      },
      isSpecialEvent: (this.currentTime && this.currentTime.hasSpecialEvent) || false,
      isFullDayEvent: !this.currentTime
    }
  },
  computed: {
    ...mapState(['employeeTourDates']),
    filteredTourDates() {
      // Filter the tour dates by how many packages this person has in their group
      const packageCount = (
        this.isSpecialEvent || this.isFullDayEvent
        ? this.$store.state.maxGroupSize
        : Number(this.visitor.quantity)
      );
      
      const arrayMethod = (
        this.isFullDayEvent
        ? 'every'
        : 'some'
      );
      
      const filteredDates = this.employeeTourDates.filter((item) => {
        return item.times[arrayMethod]((time) => {
          if (packageCount <= time.ticketsAvailable) {
            return true;
          }
        });
      });

      return filteredDates;
    }
  },
  methods: {
    filterSelectedDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      
      const selectedDate = this.filteredTourDates.find((item) => {
        return item.date.year == year && item.date.month == month && item.date.day == day;
      });

      this.selectedDatedTimes = selectedDate.times;
    },
    onDateChange(date) {
      this.selectedDate = date;
      // Clear selectedTime
      this.selectedTime = null;
      if (this.$refs.timePicker) {
        this.$refs.timePicker.clearSelectedTime();
        this.blurred.time = false;
      }

      setTimeout(() => {
        this.filterSelectedDate(date);
      }, 100)
    },
    onTimeChange(time) {
      this.selectedTime = time;
    },
    handleBlur(field) {
      this.blurred = {
        ...this.blurred,
        [field]: true
      };
    },
    close() {
      this.$emit('onClose');
    },
    onSubmit() {
      if (this.selectedDate !== null && (this.isFullDayEvent || this.selectedTime !== null)) {
        this.moveVisitor();
      } else {
        this.blurred.date = true;
        this.blurred.time = !this.isFullDayEvent;
      }
    },
    async moveVisitor() {
      // Pass receiptNumber, current tour date and time to remove, and new tour date and time to add
      // const params = {
      //   receiptNumber: this.visitor.receiptNumber,
      //   currentDate: this.currentDate,
      //   currentTime: {
      //     hour: this.currentTime.hour,
      //     period: this.currentTime.period
      //   },
      //   newDate: this.selectedDate,
      //   newTime: {
      //     hour: this.selectedTime.hour,
      //     period: this.selectedTime.period
      //   }
      // };

      const params = {
        date: this.$formatDate(this.selectedDate),
        time: this.selectedTime && this.selectedTime.time,
        order_id: this.visitor.order_id
      };
      
      const response = await this.$store.dispatch((
        this.isSpecialEvent || this.isFullDayEvent
        ? 'moveEvent'
        : 'moveVisitor'
      ), params);
      
      if (response.data.status === 'success') {
        await this.$store.dispatch('fetchEmployeeTourDates');
        // close on success
        this.close();
      }
    }
  }
}
</script>
