<template>
  <form @submit.prevent="onSubmit">
    <p class="mb-3 text-center">Are you sure you want to delete</p>
    <p class="tour-tool__description mb-2">
      {{ model.firstname }} {{ model.lastname }}, Receipt # {{ model.order_id }}
    </p>

    <p v-if="loading" class="text-center mt-4 mb-3">Loading...</p>

    <div v-else class="d-flex">
      <button type="button" @click.prevent="close" class="button button-crud mt-4">Cancel</button>
      <button type="submit" class="button button-crud mt-4">Delete</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'DeleteVistorForm',
  props: {
    visitor: Object,
    event: Object
  },
  data() {
    return {
      model: (
        this.visitor
        ? {
          firstname: this.visitor.name.first,
          lastname: this.visitor.name.last,
          order_id: this.visitor.order_id
        }
        : this.event
      ),
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    onSubmit() {
      this.deleteVisitor();
    },
    async deleteVisitor() {
      this.loading = true;
      
      const response = await this.$store.dispatch(
        (this.visitor ? 'deleteVisitor' : 'deleteEvent'),
        { order_id: this.model.order_id }
      );

      if (response.data.status === 'success') {
        await this.$store.dispatch('fetchEmployeeTourDates');
        // close on success
        this.close();
      }
      
      this.loading = false;
    }
  }
}
</script>
