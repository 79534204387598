<template>
  <div class="special-event mt-5">
    <p class="mb-2">{{ eventTypeText }} {{ specialEvent.title }}</p>
    <p class="tour-tool__description">
      Contact Person: {{ specialEvent.firstname }} {{ specialEvent.lastname }} |
      Phone {{ specialEvent.phone }} |
      Number Attending: {{ specialEvent.quantity }}
    </p>

    <div v-if="showEditControls" class="text-center mt-4">
      <button class="button button-visitors mr-1" @click="openDelete">Delete</button>
      <button class="button button-visitors ml-1" @click="openMove">Move</button>
    </div>

    <Modal :active="activeDelete" @onClose="closeDelete">
      <DeleteVisitorForm :event="specialEvent" @onClose="closeDelete" />
    </Modal>

    <Modal :active="activeMove" @onClose="closeMove" className="tool-modal--move">
      <MoveVisitorForm
        :currentDate="selectedDate"
        :currentTime="selectedTime"
        :visitor="specialEvent"
        @onClose="closeMove"
      />
    </Modal>
  </div>
</template>

<script>
  import Modal from './Modal';
  import DeleteVisitorForm from './DeleteVisitorForm';
  import MoveVisitorForm from './MoveVisitorForm';

  export default {
    name: 'SpecialEvent',
    props: {
      eventTypeText: String,
      specialEvent: Object,
      showEditControls: Boolean,
      selectedDate: Date,
      selectedTime: Object
    },
    components: {
      Modal,
      DeleteVisitorForm,
      MoveVisitorForm
    },
    data() {
      return {
        activeDelete: false,
        activeMove: false
      };
    },
    methods: {
      openDelete() {
        this.$nextTick(() => this.activeDelete = true);
      },
      closeDelete() {
        this.activeDelete = false;
      },
      openMove() {
        this.$nextTick(() => this.activeMove = true);
      },
      closeMove() {
        this.activeMove = false;
      }
    }
  };
</script>
