<template>
  <div class="summary-view">
    <div v-for="time in selectedDateObj.times">
      <div v-if="time.visitors.length > 0 || time.hasSpecialEvent" class="d-flex justify-content-between align-items-end">
        <p class="visitors__heading mb-0">
          <a href="#" @click.prevent="$emit('onTimeChange', time, true)">{{ $formatTime(time.time) }}</a>
        </p>
      </div>

      <SpecialEvent
        v-if="time.hasSpecialEvent"
        :specialEvent="time.specialEvent"
        eventTypeText="Blocked Time:"
      />

      <div v-else class="visitors__items mt-3 mb-4">
        <div v-for="(visitor) in time.visitors" class="visitors__row row text-left">
          <div class="col-4 visitors__col">
          <a href="#" @click.prevent="$emit('onTimeChange', time, true)">
          <p class="tour-tool__description mb-2">{{ visitor.name.first }} {{ visitor.name.last }}</p>
          <p class="tour-tool__description mb-2">{{ visitor.email }}</p>
          <p class="tour-tool__description mb-2">{{ visitor.phone }}</p>
          </div>
          </a>
          <div class="col-4 visitors__col">
            <p class="tour-tool__description mb-2">Party of {{ visitor.quantity }}</p>
          </div>
          <div class="col-4 visitors__col">
            <p class="tour-tool__description mb-2">Receipt #: {{ visitor.order_id }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpecialEvent from './SpecialEvent';

export default {
  name: 'SummaryView',
  props: {
    selectedDateObj: Object
  },
  components: {
    SpecialEvent
  }
};
</script>
