<template>
  <transition name="fade-employee">
    <div v-if="active === true" class="tool-modal" :class="className">
      <div class="tool-modal__bg u-absolute-full" @click="onClose"></div>
      <div class="tool-modal__container">
        <button
          class="tool-modal__close u-appearance-none"
          @click="onClose"
        >X</button> 
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    active: Boolean,
    className: String,
  },
  data() {
    return {
      focusEls: []
    }
  },
  watch: {
    active(val) {
      this.toggleFocusEls(val);
    }
  },
  methods: {
    // Trap focus
    toggleFocusEls(val) {
      const buttons = document.querySelectorAll('button');
      const inputs = document.querySelectorAll('input');
      this.focusEls = [
        ...buttons,
        ...inputs
      ];

      const tabIndex = val === true ? -1 : 0;

      this.focusEls.forEach((el) => {
        el.tabIndex = tabIndex;
      });
    },
    onClose() {
      this.$emit('onClose');
    }
  }
}
</script>
